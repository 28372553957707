import React, {useState} from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';
import {listDataCeoWhitePaper, listDevelopers, listMarketings, listDesigners} from './listdata'

const listSection = [
    {id : '1', title : ' Preface'},
    {id : '2', title : ' Discovery of problems'},
    {id : '3', title : ' Human time'},
    {id : '4', title : ' Solution-human time mining (TimeStope)'},
    {id : '5', title : ' TimeStope Economic model'},
    {id : '6', title : ' Is it really possible to apply the new economic model universally around the world?'},
    {id : '7', title : ' Raising the possibility'},
    {id : '8', title : ' Presenting a solution to the possibility'},
    {id : '9', title : ' The beauty of time and numbers'},
    {id : '10', title : ' The total amount of TIME is not determined'},
    {id : '11', title : ' Absolute principle - A person must have only one account'},
    {id : '12', title : ' Ways to earn TIME'},
    {id : '13', title : ` TimeStope's third-party program`},
    {id : '14', title : ' Human footsteps'},
    {id : '15', title : ' TimeStope movement'},
    {id : '16', title : ' We are human and are all equal in notion of time'},
    {id : '17', title : ` TimeStope's restrictions regulation`},
    {id : '18', title : ' Issuance of the TIME coupons'},
    {id : '19', title : ' Team'},
    {id : '20', title : ' Timeline'},
    {id : '21', title : ' Exemption Clause and Legal Notice'},
    {id : '22', title : ' Epilogue'},
    {id : '23', title : ' Appendix'},
];

function WhitePaperComponent(){
    const [activeSection, setActiveSection] = useState("1");
    const handleClickSection = (id) => {
        setActiveSection(id);
    }
    const renderSection1 = () => {
        return(
            <>
                <div className="text-section"><span style={{paddingLeft : 20}}></span>  The digital currency as we know it started with Bitcoin, and its contents can be found by looking at the record on January 31, 2009 on the Bitcoin homepage bitcoin.org(1) at archive.org which is a wayback machine service.</div>

                <div className="text-section"><span style={{paddingLeft : 20}}></span> "The software is still alpha and experimental. There's no guarantee the system's state won't have to be restarted at some point if it becomes necessary, although I've done everything I can to build in extensibility and versioning”.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> It is clear that Bitcoin is a software and has no absolute guarantees. It is experimental, and is a test for a new currency. Since then, countless altcoins have been created, and the world of cryptocurrency expands at an alarming rate.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> Bitcoin was a new design for a peer-to-peer electronic cash system, and enough 10 years have passed for humanity to accept the new cash transfer system. Since then, research on various forms of new payment methods has continued, and Vitalik Buterin added the concept of smart contract that allows applications to be loaded on a distributed ledger through Ethereum to the existing Bitcoin design. This opens the heyday of cryptocurrency.</div>
                
            </>
        )
    }

    const renderSection2 = () => {
        return(
            <>

                <div className="text-section"><span style={{paddingLeft : 20}}></span> Any early participants could easily acquire bitcoin, and as more and more has to participants later became, the later participants became more difficult to acquire bitcoin. Because of this reason, the unfair way of distributing assets has raised the question of where the seigniorage authority of the assets is.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> What is Seigniorage? It was as the monarch's authority to issue currency in the feudal era in the past, the method of distributing digital currency as a concept of reward to subjects discovering new blocks in a blockchain-based digital currency transaction system. This was called mining, and it becomes part of the similar method pursued by the new altcoin system even in the situation where countless cryptocurrencies are being newly developed so far. The new altcoins follow Bitcoin's successful model and start with more innovative technologies.
                </div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> The reason why the Bitcoin system was designed to reward someone who finds an each block would have been to prevent the situation where the distributed ledger could not be constantly recorded. Probably, Satoshi Nakamoto would have had to motivate the participants enough to experiment with the blockchain distributed ledger system.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> Instead, this motivational method has raised a question about unfair distribution at the time Bitcoin is recognized as a new asset. The realization of the spirit of the free era to benefit humanity with the decentralized currency that Satoshi dreamed of is rather a feeling that is far from its essence.</div>

                <div className="text-section"> <span className="number-text">1</span><span style={{paddingLeft : 10}}></span> Must ensure fair compensation for the participants.</div>
                <div className="text-section"> <span className="number-text">2</span><span style={{paddingLeft : 10}}></span> All participants must be treated fair without any discrimination, including nationality, gender, age, race, wealth's status.</div>
                <div className="text-section"> <span className="number-text">3</span><span style={{paddingLeft : 10}}></span> The compensation awarded to the participants must be reasonable, and the rationality must be such that consent can be obtained from all participants.</div>

                <div className="text-section"><span style={{paddingLeft : 20}}></span> Currently, the evolutionary process of cryptocurrency is seems to how efficiently it accepts Byzantine Fault Tolerance, and seems to focus solely on how fast it processes. Although this is not a current technology, it will develop through more research, and eventually, as the network system performance and the terminal performance of participants are improved, there will be no choice but to solve the Byzantine disorder at some point in the future. Therefore, new cryptocurrencies should do not have attention to this discourse.</div>

                <div className="text-section" style={{fontWeight : 'bold', fontStyle : 'italic', color : '#06243c'}}><span style={{paddingLeft : 20}}></span> Accepting the failure of the Byzantine algorithm through defragmentation of the overflowing block chain technology debris, Blockchain technology that oriented for tremendous speed transaction will be prepared, we have already reached the point where many technologies appear in a row, and the problem of what kind of blockchain technology to adopt or develop is no longer important. We dare to argue that no blockchain network that takes this as an important concern can guarantee success.</div>

                <div className="text-section"><span style={{paddingLeft : 20}}></span> Now, shifting to the discourse, how to make an essence of money to the fair system, and must thought for the all mankind of the survival and prosperity. New games is starting, and the blockchain currency system await new discourse.</div>
                
            </>
        )
    }

    const renderSection3 = () => {
        return(
            <>
            <div className="title-section">3.1. What is time?</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Time(2) is a concept for recognizing changes in things. Attempting to understand about time has been the main concern of philosophers and scientists since ancient times. But about the meaning of time, so there are many different perspectives Indisputable It is difficult to come up with a clear definition. Also, whether time is for just an artificial unit for measuring events, or it is not known whether what kind of some quantity as have a physical meaning that exists independently of the event. However, the world changes because time exists. Scientists do more research to define time, and thermodynamics offers the possibility for that definition.</div>
            <div className="title-section">3.2. The second law of thermodynamics</div>

            <div className="text-section"><span style={{paddingLeft : 20}}></span> There are many explanations for why time only flows linearly from the past to the future, but one of them is the second law of thermodynamics, the increase in entropy (degree of disorder). According to the second law of thermodynamics, an isolated system tends to progress in the direction of increasing entropy, eventually reaching an equilibrium state where entropy has a maximum value. According to the second law of thermodynamics, as the entropy increases, the energy distribution of spacetime changes, so that it does not return to its previous state, and the growing side automatically becomes the future where time passes. The direction of time that appears like this is called the arrow of thermodynamic time, and the direction of time that we experience is the same as that of thermodynamic time, so time does not flow backwards.</div>
            
            <div className="title-section">3.3. To humans, time is relative and absolute.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> To Isaac Newton, time was an entity that always flows uniformly, not changing anytime, anywhere along with space. In his book the Principia, he says: "The mathematical and truthful absolute time flows by itself, irrespective of anything outside." Newtonian time, which exists independently of the existence or change of things, is in achieve opposition to the Leibnizian view of time that time occurs as a which is the result of the order in which things are created. Of course, Einstein's theory of relativity proves that time is relative depending on the gravity, but that relativity cannot deny absoluteness in human recognition. Therefore, time in human of recognition is always absolute. There are 24 hours on everyday for all humans. All economic activity can be summed up as the act of trading other people's time to fill in my shortfall time.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Time is equally given to everyone. Time is objective for everyone and is a fundamental element of physics that cannot be physically increased or decreased. All the advances in physics are based on time, and understanding the nature of time can lead to the belief that time can be converted into an asset.</div>

            <div className="text-section" style={{fontWeight : 'bold', fontStyle : 'italic', color : '#06243c'}}><span style={{paddingLeft : 20}}></span> “Time is money. Time is gold. Time is precious”</div>

            <div className="text-section"><span style={{paddingLeft : 20}}></span> To humans, could there be anything fairer than time? Color, age, property, gender, race... all are different, but everyone has the same time period. It can’t be denied that everyone has the same 24 hours per day. Time is the most precious thing to human beings. How much effort did China's Emperor Qin make to increase his time?</div>

            <div className="text-section" style={{fontWeight : 'bold', fontStyle : 'italic', color : '#06243c'}}><span style={{paddingLeft : 20}}></span> "Time is the most precious. More than gold, more than money"</div>

            <div className="text-section"><span style={{paddingLeft : 20}}></span> What if a human could turn his time into an asset? Is there a way to convert time into money? In the process where bitcoin is believed to be of value from humanity, because the method of mining bitcoin adopts a method similar to the act of mining gold by humans, bitcoin was able to give participants faith in the valuable assets obtained from mining. If time could be mined, humanity would have faith in converting time into asset. Everyone has time, but they’ve never defined the means and method to record it.</div>
            
            </>
            
        )
    }

    const renderSection4 = () => {
        return(
            <>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> To propose an ideal model for mining human time, first you have to ask the question of whether it is possible. Time is physical and can be measured by thinking, but it always passes in the form of past, present, and future.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> As a way to record the past, we must see history. To record the past, No, exactly the means humans made to record the past were a recording medium for recording languages, letters, and letters. Advances in printing made it possible to record human time. However, it's accurately was to record human time, not individual time.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> If the means of recording human time was printing technique, what would be the way to record personal time? How to record that time should be fair, value should be reasonably reflected as an economic means.
            </div>
            
            </>
        )
    }

    const renderSection5 = () => {
        return(
            <>
            <div className="title-section">5.1. Decentralized Seigniorage</div>

            <div className="text-section"><span style={{paddingLeft : 20}}></span> The system we present records time, and ‘Distributed Seigniorage’ used as a currency. Seigniorage is the monarch integrates into one social system, it's for have the right to issue money for control this. As the current state has seigniorage, for numerous powers to Challenge it have begun to emerge. This was a seigniorage created by a network made with cryptocurrencies such as Ethereum, starting with Bitcoin. More specifically, it is a special seigniorage that is given only to those who discover distributed ledgers. It records transaction history with sending, then shares them to be distributed, but seigniorage is not.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Seigniorage(3) without privileges clearly distinguished from this is called ‘Distributed Seigniorage’. The principal agent of human time is an individual human being, and has 24 hours every day. All human beings are the masters of time and become subjects who have authority of Seigniorage. Yes. We are all lords in our world.</div>

            <div className="title-section">5.2. Produce the TIME with ‘Distributed Seigniorage’</div>

            <div className="text-section"><span style={{paddingLeft : 20}}></span> All human beings become one object and produce 24 hours. It produces 24 hours since you become a witness of TimeStope, and produces 24h * 365 = 8,760 hours per year. It is not produced by the network, but by individuals, and individual have authority over it.
            </div>

            <div className="title-section">5.3. Distribution for ‘Distributed Seigniorage’</div>

            <div className="text-section"><span style={{paddingLeft : 20}}></span> 40% of the time produced by an individual is distributed to the inviter who invited to the monetary system to express their gratitude, and the remaining 60% is obtained. This is a very important factor.</div>

            <div className="text-section"><span style={{paddingLeft : 20}}></span> The most important thing in the monetary system is credit. It can be concluded that credit is a social belief and believes in the monetary value of the monetary system. For this, it is a prerequisite for many people to participate, and the inviter's time increases in proportion to the quantity of the invitation.</div>

            <div className="title-section">5.4. Absolute value analysis for the 1 hour</div>

            <div className="image-section-1">
                <img alt="table 1" style={{width : '90%'}}  src={require('../../assets/images/whitepaper/timestope_img_1.png')} />
                <div className="text-table"> {"<Figure 1> Global GDP average for 2019"}</div>
            </div>

            <div className="text-section"><span style={{paddingLeft : 20}}></span> The World Bank's (4)global GDP average for 2019 is $11,428. Divided this by 8,760 hours (1 year), we reach the value of 1.3 USD. However, since that amount is actually a Whole-human average indicator index generated by human production activities, Accurately it is said that a person corresponding to the average value produces 1.3 dollars per hour even when sleeping.</div>
            <div className="image-section-1">
                <img alt="table 1" src={require('../../assets/images/whitepaper/timestope_img_2.png')} />
                <div className="text-table"> {"<Figure 2> Number of billionaires in 2017"}</div>
            </div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> According to an article from the Oxfam Report(5) January 2018, 82% of the world's asset produced in 2017 was owned by the top 1% rich, and their assets grow by 13% annually.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> This was announced at the Davos Forum in 2018 and gave many surprises around the world. Based on this, it is not too long until the 1% the rich will own 99% assets, the 1:99 rule is now more and more strongly supported. (At 2034 AD, 1% of the rich will own more than 99% of the assets that the world produces in one year).
            </div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> In addition, with the advancement of science and technology, the rapid development of AI, robotics, 5G, big data, and various financial technologies will bring a lot of changes to 99% of jobs and labor perspectives. At present, the bottom 50% of humanity in the world has zero output in dollar-denominated GDP, and the point when the remaining top 49% will reach zero will certainly happen at some point in the future. Through this basic data, if calculated without including the assets of top 1% rich person, the fundamental value of 1 hour will be reached.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> If we calculate the actual value, we reach the value of $0.0131313... ($1.3/99). It is estimated from this calculation that the intrinsic value of humanity for one hour will converge to 1.3¢ when universal humanity comprehensively considers all the results of labor, capital, mind, and rest.
            </div>
            
            <div className="title-section">5.5. A specific suggest of how to asset time</div>

            <div className="text-section"> <span className="number-text">1</span><span style={{paddingLeft : 10}}></span> The unit of the TIME currency is τ, which corresponds to the Greek letter T, and 1τ (Time [Tau]) = 1 hour.</div>
            <div className="text-section"> <span className="number-text">2</span><span style={{paddingLeft : 10}}></span> The daily output that an individual produces for a day is 24τ, and 9.6τ (24τ * 40%) is distributed to the inviter, and 14.4τ (24τ * 60%) is obtained.</div>
            <div className="text-section"> <span className="number-text">3</span><span style={{paddingLeft : 10}}></span> τ has 6 decimal places, and 0.000001τ = 1ε (entropy).</div>
            <div className="text-section"> <span className="number-text">4</span><span style={{paddingLeft : 10}}></span> The basis of TimeStope is to be a witness. Only those who have been invited by the witness can create the TIME through TimeStope, and 9.6τ is distributed to the inviter every day.</div>
            <div className="text-section"> <span className="number-text">5</span><span style={{paddingLeft : 10}}></span> When the mining period expires, you must witness for someone other than the inviter who invited you and the person you invited, and the attendance score will be reset. This is a way to consistently provide fair opportunities to new participants.</div>
            </>
        )
    }
    const renderSection6 = () => {
        return(
            <>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> An American car sales king, Joe Girard(6), came up with the Law of 250 people. He discovered this law in the fact that the number of people showed up for a person’s meetings such as wedding is approximately 250. In other words, the number one person affects another is 250 people. By using the law of 250 people that anyone can reach, all human beings become rich.</div>

                <div className="text-section"> <span className="number-text">1</span><span style={{paddingLeft : 10}}></span> The daily output produced by one person is 24τ.</div>
                <div className="text-section"> <span className="number-text">2</span><span style={{paddingLeft : 10}}></span> If one person invites 250 people, the daily output is 2,400τ (250 * 40% * 24h).</div>
                <div className="text-section"> <span className="number-text">3</span><span style={{paddingLeft : 10}}></span> The total output produced in one year is 876,000τ (2,400τ * 365).</div>
                <div className="text-section"> <span className="number-text">4</span><span style={{paddingLeft : 10}}></span> Estimated dollar amount produced in one year 876,000τ * 0.013131... (1.3¢/99)(7) = $11,503</div>
                <div className="text-section"> <span className="number-text">5</span><span style={{paddingLeft : 10}}></span> Inviting 250 people, it is close to the average global GDP of $11,435.</div>

                <div className="text-section"><span style={{paddingLeft : 20}}></span>If anyone applies the Joe Girard’s Law of 250 people, he/she can invite 250 people to the new economic system TimeStope.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> However, to apply the economic model that we argue to be applied to the theoretical and actual economic model, the exchange must actually be made, and we have a question of whether this is possible. In fact, the question arises as to whether the value of an hour can be exchanged for a value of 1.3¢.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> How was Bitcoin valued in the first place? To myriad organizations and individuals</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> As an alternative payment method for countless organizations and individuals who need anonymity, Starting with a trial payment at first, over time, as the scale grew, and gained credit as a means of payment to people. In other words, it was adopted by human needs.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> However, an alternative means to pay with anonymity already exists, and a system called the TIME based on time, which is a system different from Ethereum, which is used by the needs of organizations that want to create tokens with smart contracts like Bitcoin, I would like to suggest enough possibilities for the question of whether it can succeed.</div>
               

            </>
        )
    }
    const renderSection7 = () => {
        return(
            <>
                 <div className="text-section"> <span className="number-text">1</span><span style={{paddingLeft : 10}}></span> According to a topic listed in the World Bank(8), despite the rapidly growing number of financial account owners, it is estimated that 1.7 billion adults (31% of adults) of the world's population do not have a basic trading account.</div>

                 <div className="text-section"><span style={{paddingLeft : 20}}></span> If time is used as the most common means of payment to replace many people's bank accounts, credit can be obtained. To this end, the new monetary system must be thoroughly decentralized and fair.</div>

                 <div className="text-section"> <span className="number-text">2</span><span style={{paddingLeft : 10}}></span> Giants of the Internet world - Google and Facebook, their growth can be profitable through search-based advertising. With the rapid growth of the untact business in a global crisis caused by the corona pandemic, all industrial systems are rapidly reorganizing to online. As the non-contact economy develops dramatically in all fields of society, the sales generated through platform business increased rapidly than before the corona virus.</div>

                 <div className="text-section"><span style={{paddingLeft : 20}}></span> The new model we propose is to build another platform above the platform. The platform above the platform does not make a giant, but it can be compared to a dwarf standing upon the giant's shoulder. Cash assets created through the platform above the platform will be used as basic assets that can be exchanged for time. This is a very realistic proposal.</div>
                 <div className="text-section"><span style={{paddingLeft : 20}}></span> a universal payment method for humans is needed, and for this purpose, a payment method that uses time as an asset is applied as the main payment method of the Untact economy. If an integrated platform is created to enable universal use of the payment method, TimeStope's Success or amazing spread is possible.</div>
            </>
        )
    }
    const renderSection8 = () => {
        return(
            <>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> To this end, time that all humans can understand most easily should be marked as an asset and recorded in a reasonable way. The stored assets must be transferable, and the transferable assets must reach a realistic transfer speed. To materialize this goal, we will set up TimeStope Corporation. TimeStope's public discourse is not in the form of a foundation, but build in the form of a company, and all asset management should be transparently audited. Shareholders of TimeStope Corporation must maximize their profits with their investment shares or dividends. However, in order to establish legal review and stability of the company's form, it will be launched as a virtual company that does not have a specific form, the establishment of the company intends to start completely with the composition of shareholders completed and legal stability secured.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> In addition, it is divided into a node, a program that manages time, and a TimeStope platform (or called a third-party program) to guarantee the value of the TIME among the TimeStope’s services.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The TimeStope platform aims to reward users for their searches. For example, if a user using Google searches for the keyword "cheap flights", Google will display information related to travel advertisements on the user's terminal device. This is both information and advertising. When clicking on the advertisement, the advertiser's deposit will be withdraw, and Google taken to that amount. We search, and in return for that search, Google advertises.
</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> This is how all platforms such as Facebook, Twitter, and TikTok generate sales in the same way. However, advertisements related to certain interests, not keywords, are exposed to us. We are not compensated for any benefit for this advertisement.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> When clicking, the advertising cost incurred is also shared in the medium in which that advertisement is displayed. We are not aiming for an adversarial relationship with the existing platform.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Google released the source code for an internet browser called Chromium. We deeply appreciate Google's contribution to humanity, so that anyone can develop it. We will realize multiplatform search through the search engine.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> You just need to expose ads that appear on Google, Facebook, etc. TimeStope Corporation exposes the advertisement and distributes the revenue earned from the advertisement as the TIME to those who participate in the TIME economy system. Of course, Google and Facebook pay TimeStope Corporation with cash assets such as dollars. Part of the revenue goes to the person who clicks the ad, and the remainder is used as a means to maintain the value of the TIME. A part of the remainder is again distributed to TimeStope's shareholders. This idea has been patented(9) to prevent theft.</div>
            
            </>
        )
    }
    const renderSection9 = () => {
        return(
            <>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> TimeStope pursues the beauty of numbers. When τ = time, 1 hour is mathematical interpreted as 1τ.</div>
            <div className="image-section-1">
                    <img alt="table 1" src={require('../../assets/images/whitepaper/timestope_img_3.png')} style={{width : '90%'}}/>
                <div className="text-table"> {"<Figure 3> What is a Tau?"}</div>
            </div>

            <div className="text-section"><span style={{paddingLeft : 20}}></span> According to Collins' dictionary, it is the 19th lowercase Greek letter, and corresponds to the first letter of time, t. It is pronounced as /taʊ/. "We feel like we're seeing a wrong number every time we see a pi in recent years," said Kevin Houston, a professor at Reed University who leads the UK's Tau campaign. "The most natural and appropriate number to associate with a circle is 2π, or τ, not π".</div>
            <div className="image-section-1">
                <img alt="table 1" src={require('../../assets/images/whitepaper/2.png')} />
                <div className="text-table"> {"<Figure 4> 1τ and 2π"}</div>
            </div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> If you calculate the circumference of a circle using pi, the circumference of the circle is 2πr, but if you calculate the circumference using tau, you can calculate it as 1τr. Here, if the radius is 1, the circumference of the circle can be expressed as 1τ.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> When the length of the minute hand (radius of a circle) is 1, the minute hand draws the circumference of the clock while turn 1 round (1 hour), and the time increases by 1 hour. Therefore, 1 hour becomes 1τ</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> We will use TIME as the symbol τ, which is more complete and beautiful than the pi, which is said to be a great mathematical discovery.</div>
           
            </>
        )
    }
    const renderSection10 = () => {
        return(
            <>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Shortly before the end of World War II in 1944, the Bretton Woods System (BWS) switched from the gold conversion system to the dollar world currency. This is the most important moment in world history. After several global financial crises erupted, the gold-based monetary system was converted to a dollar-based system. This proves that total quantity is no longer meaningful in the monetary system.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The crisis was overcome through quantitative easing, and in a dollar system that can issue an unlimited number of dollars, one dollar is only one dollar, and its Intrinsic value does not has originally.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Existing cryptocurrency systems dose the limited issuance and passed time, the asset value exploded as the difficulty of distribution was reached, and the speculative tendency became stronger. Due to the success of the early participants of Bitcoin and Ethereum, numerous altcoins are developed afterwards. This is the result of greed of many networks hoping to achieve great success like Bitcoin and Ethereum, and I am sure that it will never succeed.</div>

            <div className="text-section" style={{fontWeight : 'bold', color : '#06243c'}}><span style={{paddingLeft : 20}}></span> “History repeats itself, first as tragedy, second as farce”, said Hegel.</div>


            <div className="text-section"><span style={{paddingLeft : 20}}></span> The total amount of time increases infinitely as the world exists infinitely. It will continue to increase as much as new miners who participate in it, and it will increase exactly as much as 24 hours, which is the time given per day for the participant.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> This achieves a ‘Distributed Seigniorage’ that distributes the actual production authority of the money called TIME to each individual.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Now, for example, Solomon was invited by someone 50 days ago, and Solomon invited John and David. Suppose 40 days for John and 30 days for David have passed. The three produce 24τ daily. Of these, 9.6τ is given to the inviter, and he gets 14.4τ every day. How did Solomon, John, and David's wallets change now?</div>

            <div className="image-section-1">
                <img alt="table 1" style={{width : '90%'}} src={require('../../assets/images/whitepaper/timestope_img_5.png')} />
                <div className="text-table"> {"<Figure 5> Dispersion seigniorage of the TIME"}</div>
            </div>

            <div className="image-section-1">
                <img alt="table 1" style={{width : '90%'}} src={require('../../assets/images/whitepaper/5.png')} />
                <div className="text-table"> {"<Chart 1> Calculation method to dispersion seigniorage of the TIME"}</div>
            </div>
            
            </>
        )
    }
    const renderSection11 = () => {
        return(
            <>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> In the system of the TIME, the basis of seigniorage is the existence of each person. If this is broken, the money system of the TIME will inevitably collapse due to irregular supply and demand. Everyone who participates in the TimeStope’s system is called a witness, and the duty of witnesses is to actively prove their identity. One person only produces 24τ per day. This is the first principle of the most fundamental of TimeStope's worldview. Individuals and groups that violate the most fundamental principles are permanently expelled, and all assets produced from them are destroyed. In other words, non-human entity such as organization, corporation, country, abstract group, animal, AI, etc. other than humans has no seigniorage authority.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> TimeStope creates this verification system and do its best to manage it. First, to solve the problem, we use a KYC.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> The TIME is not a system that distributes time in real time, and only witnesses who have passed KYC verification can receive it.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> The FATF (Financial Action Task Force)( 11 ) is an intergovernmental international organization established to monitor illegal funds to prevent AML (Anti-Money Laundry) and to support international cooperation systems.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> (Overall, the FATF recommends that cryptocurrency service providers implement stringent know- your-customer (KYC) requirements since cryptocurrencies “have certain characteristics that may make them more susceptible to abuse by criminals, money launderers, terrorist financiers, and other illicit actors.”)</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> TimeStope's monetary system should be designed based on the FATF's recommendations, so that it can be recognized as a useful asset value in the future.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> If one person submits the act of creating multiple accounts, the fundamental belief of one account per user is undermined, and it will break the monetary system of the TimeStope. Therefore, TimeStope Corporation does not pay time assets in real time. It has a sufficient authentication inspection period, and only distributes assets to those who have been verified. All assets produced in a negative way will be confiscated, and the assets must be incinerated or publicly recovered for social benefit.</div>
                
            </>
        )
    }
    const renderSection12 = () => {
        return(
            <>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> In TIME, the individual is the subject of production for the production. If the seigniorage is limited to individuals, it faces a limit to more actively spreading it to the world.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> First, there is a one-dimensional way of producing time yourself. Each person has 14.4τ per day.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> Second, there is a way to invite others and take the share of the invitation every day. Each person earn 9.6τ per day.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> Third, there is a way to get it through TimeStope's 3rd party program. For example, you can get the TIME rewards from TimeStope's third-party programs by searching on TimeStope's platform. This is not the seigniorage you create, but it is distributed by the seigniorage of TimeStope's third-party program.</div>
            </>
        )
    }
    const renderSection13 = () => {
        return(
            <>
            <div className="text-section"> <span className="number-text">a</span><span style={{paddingLeft : 10}}></span> The conditions for third-party programs are strictly determined. First, it is possible to convert cash equivalents or cash into third-party assets in order to generate seigniorage for third-party programs.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> For example, using a third-party search program, if the advertiser provides 5τ per click on the platform, 1τ is rewarded to the user who clicks the advertisement. The remaining 4τ is used as third-party assets to generate seigniorage for third-party programs, and some of them are portioned to TimeStope's shareholders.</div>


            <div className="text-section"> <span className="number-text">b</span><span style={{paddingLeft : 10}}></span> Among TimeStope's platforms, the conditions for games are handled a little more specially. In fact, points paid through games that require a high level of mental activity are processed from fees collected during TIME sending, assets that were produced and confiscated in a negative way, and profits made through donations.</div>
            <div className="text-section"> <span className="number-text">c</span><span style={{paddingLeft : 10}}></span> It can be increased by quantitative easing policy. In the distant future, when the members of TimeStope Corporation's shareholders are finalized, it can be increased by their voting, their share ratio, and voting of the TIME system participants, and this must be derived through a sufficient consensus process. For example, there may be a method of using a voting system in which TimeStope Corporation shareholders have 50% of the total decision authority and all participants have 50% of decision authority.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The platform is of great value as a third party of TimeStope. We do not compete with other platforms. By integrating their platform, they will be used to keep their profits intact, share the profits generated by coexisting with them to TimeStope participants, and create absolute credit that maintains the value of TIME.</div>

            <div className="title-section">13.1. TimeStope’s search engine</div>
            <div className="text-section"> The TimeStope search engine is a search-based mining method that uses keywords to search for treasure.</div>
            <div className="text-section"> The Timestope search engine = The goodness search engine. The meaning of goodness is that the participants who participated in the search query are also rewarded for advertisements.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Reward is also occurred when a search query is attempted, but the keyword rewarded by the search query is not compensated for a certain period of time, and if a nonsensical search is performed in one account, the corresponding action is filtered in the search browser.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> In addition, when goods flow into TimeStope from a specific web page by clicking on an advertisement, compensation will be provided for the corresponding action.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Compensation is not made in real time, but in a way that compensates the accumulated amount of the amount generated by the keyword search.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Therefore, compensation may be made only for specific keywords, and compensation is not performed for keyword search for which the amount is not accumulated.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The amount distributed to users is the accumulated amount in Timestope's search engine from some of the goods inflow from various search services due to relevant keyword, so the difference of cumulative amount is according to the important of search query term, and all rewards are rewarded by TIME.</div>

            <div className="title-section">13.2. New DeFi Solution = TimeMarketCap</div>
            <div className="text-section">Time Market Cap begins with a clear recognition of the limitations of the existing DeFi system. </div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The DeFi model was created in Ethereum-based UniSwap, and participants experienced the exchange of decentralized digital assets through the asset swap method.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> DeFi is a method of distributing transaction fees as much as the share supplied when the asset is traded through the liquidity supply of encrypted digital assets, and it is becoming a new trend in cryptocurrency that can generate the profit as much as the supply.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The problem with the existing DeFi is that, as all transactions are made on the Ethereum platform, gas costs continue to rise due to the rapidly increasing transaction volume. As a result, the transmission speed of the Ethereum network is approaching its limit.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> In systems such as Ethereum, sharding tries to apply a big data-based technology to the blockchain, but to apply sharding technology, it is impossible to absolutely accept the decentralized blockchain method.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> For example, assuming that the amount of data in one transaction is at least 500 bytes, and processing 1 block per 10 seconds, the size of one block becomes 5 gigabytes. If it is generalized that transactions involving complex smart contracts are executed, the size of the corresponding block can only exceed the basic size.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> With the current technology, it is almost impossible for all distributed ledgers to continuously store 5 gigabytes every 10 seconds without interruption.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> To solve this problem in several blockchain networks, various methods such as sharding, intermediate node, and virtual node have been proposed. Each method sounds different, but they are the same in terms of concept.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> How to solve this in a realistic way, and a new blockchain node that can accommodate future- oriented issues should be proposed.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The mainnet node proposed by Timestope intends to reach a transmission rate of 10 Million /10 Seconds, but so far, there is no technology and network to carry out this.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> “However, this will certainly be possible in the future.”</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The future blockchain mainnet should be designed to be smarter, have the openness to provide future-oriented services as current technology, and should be designed to gradually increase the speed based on participants.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The point to be mentioned is that the transmission rate must be determined variably based on the participants who are currently participating. In addition, a variable design should be based on the current technology so that the variable situation can be applied to the current technology.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The node of TimeStope consists of many nodes that process approving user registration, a server node in charge of writing, a general node in charge of reading, and a wallet program.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Timestope allows only verified users based on KYC to participate in the blockchain system, so there is a separate node for registering users.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> All nodes of TIME can be installed and applied by anyone, but they are divided into server nodes and general nodes to process transactions, and server nodes are nodes that have been verified by Timestope that they can handle a certain amount of blocks per 10 seconds, and the nodes which are not verified will be a normal node.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> This means the server node only performs the task of approving user registration, and all other write work is handled by the server node. All server nodes' write operations are stored in the distributed ledger of the blockchain, and readable normal node's operations are not stored.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> All users read the blockchain from the server node and use it by connecting to the server node's wallet application to perform the faster. Here, the server nodes receive the user's PrivateKey, and there is a security problem in which the privateKey of the user's wallet can be stolen by the owner of the server node.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> To prevent this, the user's PrivateKey generates a one-time password encoded based on Unix time and sends it to the server node, and it is designed to ensure that there is no server node can arbitrarily steal the user's PrivateKey.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> This method uses the OTP authentication for asset transmission in the blockchain, and because it transmits assets based on a one-time password, it will provide a technology for faster processing of large-capacity blockchain system.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> If the guarantee of fast transmission is handled only by the server node, this will have the same effect as sharding claimed by Ethereum of Vitalik Buterin.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Timestope's node is designed to use 1 Entropy (0.000001TIME) per 1 byte as a fee in a way that sets a fee based on the amount of data in the transaction.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The fee is fixed at 1 entropy per byte, and the fee is simply measured as much as the amount of data to be transmitted, and there is no need to do an unreasonable task of charging the gas fee for transmission.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Of course, Ethereum's system also has a different fee depending on the amount of data in the smart contract, but it is designed to have priority by paying a higher fee for faster transfer.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> This can be said to be a limited design that does not solve the problem of transmission speed and cost by designing without considering the scalability due to the number of users.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> To check the change in the amount of users, all users must be registered in the blockchain, and the processing speed and storage amount are processed by the block must be increased by the number of registered users.</div>

            <div className="title-section">13.3. Virtual Earth called Bloodland</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Before TimeStope was developed, there was a project that tried to test a virtual economy based on a virtual earth, and Bloodland, a virtual earth project, is still undergoing various experiments.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Bloodland is designed to be fused with other platforms, and Timestope will be fused with TIME's economic system.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Blaaland is used as the main means of connecting the boundary between Timestope and Bloodland, and the owner of Blaaland receives the TIME as a reward for owning Blaaland.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The 1 TIME per blaaland will be distributed daily.</div>
            
            </>
        )
    }
    const renderSection14 = () => {
        return(
            <>
             <div className="text-section"><span style={{paddingLeft : 20}}></span> Humanity makes money, letters, and electricity to drive the development of civilization, and through communication media, humans evolve into a more social and economic existence. Let imagine about primitive mankind. You are much freer, smarter, and richer than in the past. Humans are constantly evolving, and technology is becoming highly developed.</div>
             <div className="text-section"><span style={{paddingLeft : 20}}></span> With the advent of Google, the world has become smarter, faster, and attained tremendous achievements. Google says “Don’t Be Evil”, this is really important. It is the spirit of this era, and if it expands more, the world will develop more openly</div>
             <div className="text-section"><span style={{paddingLeft : 20}}></span> Satoshi Nakamoto's P2P monetary system makes it possible to think in earnest about the movement of the seigniorage that only the state can have. The authority of the state was like God, but now humans must be first than the state. We must all survive, and both the rich and the poor must coexist.</div>
             <div className="text-section"><span style={{paddingLeft : 20}}></span> A world that compels sacrifice is not right. If someone force sacrifice on you, the one who force the sacrifice is a hypocrite. That sacrifice could be time, money, or life. Sacrifice must be voluntary only, no philosophical coercion must be made, and mankind must reward those who make that voluntary sacrifice. It is not to sacrifice the rich to flourish the poor. Just as Siddhartha realized himself, and just as Jesus Christ tried to save the world by awakening himself by the Word of God, we all lead mankind into the era of peace through that voluntary sacrifice.</div>
             <div className="text-section"><span style={{paddingLeft : 20}}></span> Elon Musk said he wants to use all of his assets to lay the groundwork for humanity to survive perpetually as a pluripotent species. Even if his sincerity sometimes leads to inadequate results, his affection for humans can be felt infinitely.</div>
            
            </>
        )
    }
    const renderSection15 = () => {
        return(
            <>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The goodness as we know it can turn out to be a lie in the very distant future, like a masked wolf, and something is known as evil can later turn out to be good. There are those who take advantage of our hearts for business through hypocrisy as a mask of goodness. Those who use someone's pain to pursue profits by playing with human sensibility as a means of making money thoroughly from the earthquake in Haiti, the current corona pandemic, and a young boy who has been skinny by AIDS. Humanity has always been. Those who practice hypocrisy use the anger and sorrow of the others to arouse them, and exploiting all the profits from them. And, it wraps it like a realm of divine inviolability.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> There is no free lunch in this world. All the laws of physics in the world are fair, so if someone has it, someone has been taken. It is clear that this is not about the rich and the poor. Also, it's not that the rich are bad. The rich pay taxes as much as they earn, so they should be respected. Even if they are not discriminatory taxes, they are committed to humanity as paying more taxes. The other words for hypocrisy is evil, and it produces more terrifying consequences than visible evil. What's really bad are those who take everything in the world as exploiting at no cost.</div>
            
            </>
        )
    }
    const renderSection16 = () => {
        return(
            <>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Humanity has developed through cooperation, love for humans, and praise for the beautiful things. As a social being, humanity wants to have a different look than more other people as more specially, they want to be rich and beautiful. All these aspirations must be used in the direction of human development along with peace, and for this, a movement must be initiated to use a new payment method as time that is fair to humans.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> The definition of rich in Robert Kiyosaki's "Rich Dad, Poor Dad" is simple. No matter how much you earn, if you have to constantly work in order to live your life, you are poor.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Anyone can invite 250 people, and they are all witnesses to the goal of peace and prosperity for all mankind. Rich and poor, women and men, everyone is human, and we are lords of this world as multi-planetary species. Look again at Joe Girard's Law of 250 People.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> We are poor, not 1% rich. And don't get down on that. Through TimeStope, you can get rich.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> Why 250 people? Inviting 250 people makes you rich. Being rich means that you can live your entire life with average income of the human. You are never relatively poor now.</div>
            </>
        )
    }
    const renderSection17 = () => {
        return(
            <>
             <div className="text-section"> <span className="number-text">a</span><span style={{paddingLeft : 10}}></span> The attendance score is 100 points for initial participation. Attendance must be checked in the attendance book within 24h hours based on UTC. If you check attendance, your attendance score increases by 2 points, and if you do not check attendance, your attendance score decreases by 3 points. Keep in mind that if you are absent for exactly 34 days, that participant will not be able to join in the TimeStope permanently.</div>
             <div className="text-section"> <span className="number-text">b</span><span style={{paddingLeft : 10}}></span> When checking attendance, third-party assets are compensated for visible advertisements.</div>
             <div className="text-section"> <span className="number-text">c</span><span style={{paddingLeft : 10}}></span> When the attendance score reached a certain score, it does not decrease below that anymore. For example, if your attendance score reaches 300 points, 300 points will become a base camp, and only go up to more than 300 points, and if you do not attend, only go down until 300 points. There is a base camp for each 100 points after attendance score 300 points.</div>

             <div className="image-section-1">
                <img alt="table 1" style={{width : '90%'}} src={require('../../assets/images/whitepaper/6.png')} />
                <div className="text-table"> {"<Chart 2> Compensation example based on attendance score"}</div>
            </div>

             <div className="text-section"> <span className="number-text">d</span><span style={{paddingLeft : 10}}></span> When the attendance score reaches a certain score, the transferable amount per year increases. For example, when the attendance score reaches 300 points, the amount of sendings per year increases from 50,000τ to 500,000τ, and the amount of sendings per year increases by 200,000τ every 100 points after attendance score 300 points.</div>
             <div className="text-section"> <span className="number-text">e</span><span style={{paddingLeft : 10}}></span> All participants equally have the End-time (mining period) of 24 months. This is a way to provide fairness to members who sign up late in time. However, when 250 of the witnesses invited by an individual completed the KYC, the end time will be extended 24 months, the End-time will be extended by 24 months, and the End-time increases even if the attendance score reaches a certain score. For example, when the attendance score reaches 300 points, 150 days increase, so the End- time become about 29 months (24 months + 150 days). In addition, the End-time increases by 75 days every 100 points after attendance score 300 points. The end of the End-time means that the existing attendance score is reset to 100, and the witnesses I invited will disappear from the list. However, the ownership of the TIME that has been held until now is maintained. The meaning of the limitations of End-time is to give equal opportunities to users who will join in the future, and to encourage the steady activities of existing members who are already active as witnesses.</div>
             <div className="text-section"> <span className="number-text">f</span><span style={{paddingLeft : 10}}></span> The TIME strictly verifies 1 account per person through KYC. A tool called KYC is used to distribute new quantities to participants on a specific date each year. Initially, to increase the usage rate of users, the authentication process is simplified, but in the future, all members must prove that they have only one account per person due to strict standards. Officially, a person can only have one account, and if fraud is found, the account will be blocked and all assets are confiscated. Therefore, when conducting a transaction between individuals, and it is necessary to consider whether or not the person is authenticated, and a method to verify this through an official tool will be provided.</div>
             <div className="text-section"> <span className="number-text">g</span><span style={{paddingLeft : 10}}></span> Reward for purchasing items at the TimeStope shop - Direct sales of cryptocurrency may violate the law in some cases. In order not to directly trade cryptocurrency, TimeStope Corporation will accept sales and donations of goods.</div>

             <div className="text-section" style={{paddingLeft : 20}}><span className="number-text">1</span><span style={{paddingLeft : 10}}></span> TimeStope can sell goods and compensate for the goods sold. As a method of compensation, a part of the amount can be compensated with TIME in the assets of a third- party program, or preferential treatment can be provided for the TimeStope restrictions mentioned above.</div>
             <div className="text-section" style={{paddingLeft : 20}}><span className="number-text">2</span><span style={{paddingLeft : 10}}>Donation to the TimeStope Movement - Goods and the TIME are not compensated for donating, and part of the donation is converted into assets of third-party programs. (However, in the sense of appreciation for donations, preferential treatment can be provided for the restrictions of TimeStope).</span> </div>
             {/*  */}

             <div className="text-section"> <span className="number-text">i</span><span style={{paddingLeft : 10}}></span> Participated as a shareholder of Timstope Corporation – Up to now (August 2020), TimeStope Corporation is not established in any country. This is when it comes to raising funds, and a strict legal review must be conducted on how to participate as a shareholder. However, in order to decide this, cryptographic assets such as Bitcoin and Ethereum or electronic payment method in the form of down payments (PayPal, Stripe) can be used to participate in payment. Since the down payment paid by electronic payment method is not direct income from TimeStope Corporation, a tax invoice cannot be issued, and tax specifications for any purpose cannot be established.</div>

             <div className="text-section" style={{paddingLeft : 20}}><span className="number-text">1</span><span style={{paddingLeft : 10}}></span> Dividend Shares Without Voting Rights - TimeStope started with an organization called Bloodland, which seeks to realize humanity through human blood donation. Bloodland's assets consist of BLOOD and BLAA, and by the market value of the asset can be converted to a dividend stock without voting rights of 0.01% per 10,000 USDT. It is issued by TimeStope Corporation only in the case of a contract for conversion, and this stock will be used exclusively for dividend of TimeStope's share. All Bloodland assets provided to TimeStope Corporation are incinerated. However, this condition can be applied temporarily, and if there is a change, it will be announced through the community. Dividend stocks without voting rights can be purchase and transferred freely.</div>

             <div className="text-section" style={{paddingLeft : 20}}><span className="number-text">2</span><span style={{paddingLeft : 10}}></span> TimeStope Corporation may draw up a contract for the acquisition and transfer of shares with voting rights or shares without voting rights at a specific point of time. The types of shares are divided into transfering of old stocks and issuing of new stocks through legal reviews, and can be handled in any reasonable manner based on legislation.</div>

            </>
        )
    }
    const renderSection18 = () => {
        return(
            <>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> TIME is issued 24 hours a day per person by seigniorage of an individual or an official affiliated company (12). In addition, additional issuance is possible on a limited basis by Timestamp's Third Party Program. For example, it is additionally issued according to specific conditions such as Time Searching engine, Defi Solution, and Blaaland. However, due to the special purpose of TimeStope Corporation, if there is a case in which it is necessary to create a collateral that guarantees the same value as TIME for a special purpose, regardless of the individual's seigniorage and third-party programs. For example, if it is necessary to issue a certificate of equal value to TIME for various purposes such as publicity, activation of the community, or recruiting excellent talent, TimeStope Corporation can issue a time coupon. A TIME coupon is kind of a promissory note, and must be exchanged for TIME through third-party programs or other profits in the future.</div>
            <div className="text-section"><span style={{paddingLeft : 20}}></span> TIME coupons are processed in such a way that if a special purpose is resolved, or it is incinerated as the exchange is completed with TIME, and if recovery is not possible, the TIME is deposited for the amount that was not recovered. After that, it is possible to restrict the circulation of coupons after processing in a way that exchanges them to the corresponding owner later.</div>
            
            </>
        )
    }
    const renderSection19 = () => {
        return(
            <>
                {listDataCeoWhitePaper.map((item,index) => {
					return(
						<div className='row row-team-member' key={index}>
							<div className="col-lg-4 col-sm-4 col-12">
								<img style={{width : '100%'}} alt={item.name} src={item.avatar} />
							</div>
							<div className="col-lg-8 col-sm-8 col-12">
								<div className="name">{item.name}</div>
								<div className="position">{item.position}</div>
								<div className="infomation-1">{item.informationOne}</div>
								{item.informationTwo !== '' ? <div className="infomation-2">{item.informationTwo}</div> : null}
							</div>
						</div>
					)
				})}
                <div className="title-team">Developers</div>
                <div className='row row-team-member-employ'>
                    {listDevelopers.map((item,ind) => {
                        return(
                            <div className="col-lg-4 col-sm-4 col-6 customize-col-dev" key={ind}>
                                <img  style={{width : '100%'}} alt={item.name} src={item.avatar} />
                                <div className="name">  {item.name} </div>
                                <div className="position">{item.position}</div>
                            </div>
                        )
                    })}
                </div>

                <div className="title-team">Marketing Managers</div>
                <div className='row row-team-member-employ'>
                    {listMarketings.map((item,ind) => {
                        return(
                            <div className="col-lg-4 col-sm-4 col-6 customize-col-dev" key={ind}>
                                <img  style={{width : '100%'}} alt={item.name} src={item.avatar} />
                                <div className="name">  {item.name} </div>
                                <div className="position">{item.position}</div>
                            </div>
                        )
                    })}
                </div>
                

                <div className="title-team">Designers</div>
                <div className='row row-team-member-employ'>
                    {listDesigners.map((item,ind) => {
                        return(
                            <div className="col-lg-4 col-sm-4 col-6 customize-col-dev" key={ind}>
                                <img  style={{width : '100%'}} alt={item.name} src={item.avatar} />
                                <div className="name">  {item.name} </div>
                                <div className="position">{item.position}</div>
                            </div>
                        )
                    })}
                </div>

                <div className="text-section"><span style={{paddingLeft : 20}}></span> The team member of TimeStope Corporation will consist of internal team members and external team members. The condition of external team members is an individual or organization that can help develop the worldview of a socially influential influencer or TimeStope, The criterion for social influence is that it has more than 1,000 followers or subscribers on a social basis, and is still active. If approved as an external team member of TimeStope Corporation, there are no restrictions about the number of annual transfers and mining periods based on attendance points during the activity period, and you can receive part of the profits of the TimeStope platform, and It can be presented the opinions about important decision issues, and in some cases, you can receive a certain amount of support and rewards. In addition, you will receive a special mark in the TimeStope app, and will have priority exposure authority to opinion, etc.</div>
                
            </>
        )
    }
    const renderSection20 = () => {
        return(
            <>
                <div className="image-section-1">
                    <img alt="table 1" style={{width : '90%'}} src={require('../../assets/images/whitepaper/timestope_img_6.png')} />
                    <div className="text-table"> {"<Figure 6> TimeStope roadmap"}</div>
                </div>
            </>
        )
    }
    const renderSection21 = () => {
        return(
            <>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> Please carefully read all content of hereof exemption clause. If you are uncertain of your future actions, please consult with legal, financial, tax, and other professionals.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> 1. Hereof whitepaper is only distributed for reference purpose in relation to TimeStope project when written, and may be reviewed or modified. Based on the dates on the cover of hereof whitepaper, the latest information is reflected, however it is not the final copy. Information stated in hereof document, such as business operation and financial status of TimeStope project, may be changed after the relevant date. Hereof whitepaper may be updated occasionally.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> 2. Obligation to sign a contract or take a legal pledge in regards to purchase of TIME does not belong to anyone and funds cannot be received or accepted using hereof whitepaper as basis. Purchase of TIME shall only be completed through contract or legal agency, and the parties to the transaction may not receive any form of legal protection on transaction and purchase completed through means not stated. In case of discordance in content of the contract and hereof whitepaper, the contract takes priority.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> 3. Hereof whitepaper, under any circumstances, shall not be interpreted as coin sales or purchase proposal of TIME issuer/distributor/business, and presentation of hereof document or the document itself shall not be used as a basis or be dependent for contract or investment decision.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> 4. Hereof whitepaper is not provided as business plan or proposal, and shall not be interpreted as investment proposal or advertisement, such as security and business trust or group investment plan, in any jurisdiction.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> 5. TIME shall not be understood, interpreted, categorized, or handled as an opportunity for purchasers to participate, invest, or receive profit/income/payment/benefit or certain.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> 6. The whole or parts of hereof document cannot be copied, distributed, or disseminated in jurisdiction where coin issue means stated in hereof whitepaper is regulated or restricted.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> 7. If TIME purchase is desired, TIME cannot be understood, interpreted, categorized, or handled as the following. Other currency besides cryptocurrency; bonds and stocks issued by a certain organization; difference contract and other authority under contract that are impersonating, stock unit such as group investment plan and business trust, or derivatives with the purpose of authority, option, derivatives, investment return guarantee, or loss aversion on such bonds and stocks.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> Distributing or disseminating the whole or parts of hereof whitepaper may be prohibited or restricted by legislations or regulatory requirements of any jurisdictions. In the case of restrictions being applied, you need to be well-informed on your own on restrictions that may be applied due to the possession of hereof whitepaper, and comply. TimeStope, staff, representative, and relative shall not take responsibility for such.</div>
                <div className="text-section"><span style={{paddingLeft : 20}}></span> In the case of reading or possessing hereof whitepaper through distribution or dissemination, regardless of any purpose, sharing with others, allowing such to happen, or providing cause of such by distributing or copying the content of hereof whitepaper or the whitepaper itself shall not be permitted.</div>
                
            </>
        )
    }
    const renderSection22 = () => {
        return(
            <>
                <div className="text-section">I'm sorry, but I don't want to be an emperor.</div>
                <div className="text-section">That's not my business.</div>
                <div className="text-section">I don't want to rule or conquer anyone.</div>
                <div className="text-section">I should like to help everyone if possible.</div>
                <div className="text-section">Jew, Gentile, Black Man, White, we all want to help one another, human beings are like that.</div>
                <div className="text-section">We want to live by each other's happiness, not by each other's misery.</div>
                <div className="text-section">We don't want to hate and despise one another.</div>
                <div className="text-section">And this world has room for everyone, and the good Earth is rich and can provide for everyone.</div>
                <div className="text-section">The way of life can be free and beautiful, but we have lost the way.</div>
                <div className="text-section">Greed has poisoned men's souls, has barricaded the world with hate, has goose-stepped us into misery and bloodshed.</div>
                <div className="text-section">We have developed speed, but we have shut ourselves in.</div>
                <div className="text-section">Machinery that gives abundance has left us in want.</div>
                <div className="text-section">Our knowledge has made us cynical, our cleverness hard and unkind.</div>
                <div className="text-section">We think too much and feel too little.</div>
                <div className="text-section">More than machinery, we need humanity.</div>
                <div className="text-section">More than cleverness, we need kindness and gentleness.</div>
                <div className="text-section">Without these qualities life will be violent, and all will be lost.</div>
                <div className="text-section">(Ellipsis)</div>
                <div className="text-section">Then in the name of democracy, let us use that power, let us all unite!</div>
                <div className="text-section">Let us fight for a new world, a decent world that will give men a chance to work, that will give youth a future, and old age a security.</div>
                <div className="text-section">By the promise of these things, brutes have risen to power.</div>
                <div className="text-section">But they lie!</div>
                <div className="text-section">They do not fulfill that promise!</div>
                <div className="text-section">They never will!</div>
                <div className="text-section">Dictators free themselves, but they enslave the people!</div>
                <div className="text-section">Now let us fight to fulfill that promise!</div>
                <div className="text-section">Let us fight to free the world, to do away with national barriers, to do away with greed, with hate and intolerance.</div>
                <div className="text-section">Let us fight for a world of reason, a world where science and progress will lead to all men's happiness.</div>
                <div className="text-section">Soldiers! In the name of democracy, let us all unite!</div>
                <div className="text-section">Last speech of the Great dictator(1940)</div>
            </>
        )
    }
    const renderSection23 = () => {
        return(
            <>
             <div className="title-section">23.1. Updated</div>

            <div className={'row custom-row-table'}>
                <div className={'col-lg-2 col-md-2 col-2 custom-col2-23'}>
                    Ver 1.1
                </div>
                <div className={'col-lg-10 col-md-10 col-10 custom-col10-23'}>
                    <div className={'text-section'}>a. Changed the word of KYC.direct to KYC.</div>
                    <div className={'text-section'}>b. Changed compensation content (amount of sendings per year and End- time) according to attendance score</div>
                    <div className={'text-section'}>c. Changed the word of the Stopeterm to the End-time</div>
                    <div className={'text-section'}>d. Long-term non-users(17-g) items</div>
                    <div className={'text-section'}>e. Updated Team member</div>
                </div>
            </div>
            </>
        )
    }
	return(
		<div className="App">
		{/* Render Header */}
	      <HeaderComponent activePage={"09"} />
		{/* Render Body */}
		<div className={"white-paper"} id="white-paper"> 
            <div className="container-white-paper">
				<div className="title-white-paper">
					<span className='text-header'>TimeStope's White Paper</span>
					<img alt="TimeStope’s Team" src={require('../../assets/images/1.png')} />
				</div>
                <div className="Content-white-paper">
                    <div className="row custom-row">
                        <div className="col-lg-3 col-sm-4 col-12 customize-col-left">
                            {listSection.map((item, ind) => {
                                return (
                                    <div className={`item ${activeSection === item.id ? 'active' : ''}`} key={ind} onClick={() => handleClickSection(item.id)}>
                                        <span className="color-font-weight">{item.id}.</span> {item.title}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="col-lg-9 col-sm-8 col-12 customize-col-right">
                            <div className='description'>
                                {activeSection === '1' && renderSection1()}
                                {activeSection === '2' && renderSection2()}
                                {activeSection === '3' && renderSection3()}
                                {activeSection === '4' && renderSection4()}
                                {activeSection === '5' && renderSection5()}
                                {activeSection === '6' && renderSection6()}
                                {activeSection === '7' && renderSection7()}
                                {activeSection === '8' && renderSection8()}
                                {activeSection === '9' && renderSection9()}
                                {activeSection === '10' && renderSection10()}
                                {activeSection === '11' && renderSection11()}
                                {activeSection === '12' && renderSection12()}
                                {activeSection === '13' && renderSection13()}
                                {activeSection === '14' && renderSection14()}
                                {activeSection === '15' && renderSection15()}
                                {activeSection === '16' && renderSection16()}
                                {activeSection === '17' && renderSection17()}
                                {activeSection === '18' && renderSection18()}
                                {activeSection === '19' && renderSection19()}
                                {activeSection === '20' && renderSection20()}
                                {activeSection === '21' && renderSection21()}
                                {activeSection === '22' && renderSection22()}
                                {activeSection === '23' && renderSection23()}
                            </div>
                        </div>
                    </div>
                    <a className="download-blaa-white-paper" href="/TimestopeWhitePaper.pdf" target="_blank">
                        <span className="text-download">Download TimeStope's White Paper</span>
                        <img alt="Icon download whitepaper" className="icon-download" src={require('../../assets/images/52.png')} />
                    </a>
                </div>
			</div>
		</div>
		{/* Render Footer */}
	      <FooterComponent />
    	</div>
	)
}
export default WhitePaperComponent;